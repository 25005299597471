// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------
@import "./variables";

svg {
    display: block;
}

a:hover {
    text-decoration: none;
}

label {
    margin-bottom: 0.25rem;
}

//set color

.bg-hover {
    background-color: $hover-color !important;
}
.bg-gray {
    background-color: rgb(247, 247, 247) !important ;
}
.bg-hover-light {
    background-color: rgb(253, 243, 243) !important;
}

.bg-primary-trans {
    background-color: $primary-color-50 !important;
}

.bg-note {
    background-color: #fffbeb;
}

//set size util
.w-fit-content {
    width: fit-content;
}
//button

.btn-disabled {
    // background: $accent-color 0.3;
    background: $disabled-color !important;
    cursor: auto;
    border: none !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

//flex

.flex-column {
    display: flex !important;
    flex-direction: column !important;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

//text
.text-disabled {
    color: $text-disabled-color !important;
}

// app layout
.app-layout__sidebar-header {
    background-color: white !important;
    border-right: 1px solid $line-color !important;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

//border
.border-left {
    &-5 {
        border-left: 5px solid $line-color !important;
    }
    &-5-primary {
        border-left: 5px solid $primary !important;
    }
}

//hide iframe right below the body tag
body > iframe {
    display: none !important;
}

//input text
.input-text-quantity__container {
    max-width: 150px;
    .d-input-text__input-container {
        min-height: 30px !important;
    }
    input {
        min-width: 50px;
        text-align: center;
    }
    .d-input-text__prefix-container,
    .d-input-text__suffix-container {
        padding: 8px !important;
    }
    .d-input-text__input {
        padding: 8px !important;
    }
}

//radio antd design
.ant-radio-inner::after {
    top: 11px;
    left: 11px;
}

//over flow
.overflow-normal {
    overflow-wrap: normal;
}

.overflow-scroll {
    overflow: scroll;
}

.scroll-hide-indicator::-webkit-scrollbar {
    display: none;
}

//table antd
.ant-table-cell {
    overflow-wrap: normal !important;
}

@media screen and (max-width: 1000px) {
    .d-table-awesome-component .ant-table-tbody > tr > td::before {
        font-size: 14px !important;
    }
}

.dot-view {
    background-color: #de0d0c;
    padding: 2px 6px;
    border-radius: 10px;
}

// view absolute
.absolute-right {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
}

.object-contain {
    object-fit: contain !important;
}

.display-mobile-layout {
    // visibility: hidden;
    // height: 0px !important;
    display: none !important;
}

.display-desktop-layout {
    // visibility: visible;
    // height: auto !important;
    display: block !important;
}

@media screen and (max-width: 576px) {
    .display-mobile-layout {
        // visibility: visible;
        // height: auto !important;
        display: block !important;
    }
    .display-desktop-layout {
        // visibility: hidden;
        // height: 0px !important;
        display: none !important;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 576px) {
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px !important;
    }
}
