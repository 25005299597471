.uploadImagesModule {
    background: #ebebeb;
    border-radius: 999px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 125px;
    .uploadImageModule_avatar-container {
        position: relative;
    }
    .uploadImageModule_avatar-container-icon {
    }

    .uploadImageModule_upload-view {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.uploadImagesModule:hover {
    cursor: pointer;
    .uploadImageModule_avatar-container-icon {
        display: flex !important;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;
        border-radius: 999px;
        .material-icons {
            display: block !important;
            color: white;
        }
    }
}

.avatarPhoto {
    height: 100%;
    width: 100%;
    border-radius: 999px;
    object-fit: cover;
    /* border-radius: 0.5em;
    margin: 0.5em 0.5em 0.5em 0; */
}
