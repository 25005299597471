.signaturePadViewContainer {
    position: relative;
    width: 600px;
    height: 300px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.signaturePadView {
    position: absolute;
    left: 0;
    top: 0;
    width: 600px;
    height: 300px;
    border: 1px solid #dadada;
}

.buttonClearSignature {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 5px;
    z-index: 100000;
    :hover {
        cursor: pointer;
    }
}

@media screen and (max-width: 576px) {
    .signaturePadViewContainer {
        width: 100vw;
    }
    .signaturePadView {
        width: 100vw;
    }
    .signature-field-modal {
        .d-modal__children {
            padding: 0px !important;
        }
    }
}
